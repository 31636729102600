import templateStr from 'text!./pm-quote.html';

export default [() => {
    return {
        restrict: 'E',
        scope: {
            quoteData: '=',
            buy: '<',
            error: '<'
        },
        template: templateStr,
        controller: ['$scope', '$q', 'brandingData', 'StaticDocumentsService_AND', 'RenewalService_AND', '$window', '$state', 'ModalService', 'qnb.PolicyService', '$rootScope', 'EventHub', 'StateUtils', ($scope, $q, brandingData, StaticDocumentsService_AND, RenewalService_AND, $window, $state, ModalService, PolicyService, $rootScope, EventHub, StateUtils) => {
            const submission = $scope.quoteData.submission.value;
            $scope.COVERAGES = $scope.quoteData.submission.lobData.personalMotor.value.BRAND_COVERAGES[brandingData];
            $scope.brand = brandingData.toLowerCase();
            $scope.productCode = $rootScope.productCode;
            $scope.DefactoDateEvaluation = PolicyService.getDefactoDate_AND()
                .then((scriptDate) => {
                    $scope.isPolicyEffDateBeforeItbDefactoDate = submission.isPolEffDateBeforeDefactoDate(scriptDate) && $scope.brand === 'itb';
                });

            $q.all([
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_POLICY, submission.baseData.periodStartDate),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_NOTICE, submission.baseData.periodStartDate),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.CORE_IPID, submission.baseData.periodStartDate),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.COOKIE_POLICY, submission.baseData.periodStartDate),
            ]).then((result) => {
                $scope.privacyPolicyDoc = result[0];
                $scope.privcyNoticeDoc = result[1];
                $scope.coreIpidDoc = result[2];
                $scope.cookiePolicyDoc = result[3];
            });

            $scope.showMarketingPref = $scope.quoteData.submission.value.isAggsQuoted_AND();
            $scope.vehicle = $scope.quoteData.submission.lobData.personalMotor.coverables.pmVehicles.children[0].value;

            $scope.next = (callback) => {
                $scope.form.submitted = true;
                if ($scope.form.valid === false) {
                    callback();
                    return;
                }
                // Chosen quote is stashed in Stateutils to use in Payment Screens
                const selectedQuote = $scope.quoteData.offeredQuotes.find((quote) => quote.isCustom === true);
                // Save coverage selection on front-end
                const coverageSelection = {};
                Object.keys($scope.COVERAGES).forEach((key) => {
                    const coverage = $scope.quoteData.getCoverage($scope.COVERAGES[key], selectedQuote);
                    if (coverage) {
                        StateUtils.activeDataForState(true).coverageSelection[$scope.COVERAGES[key]].selected = coverage.selected;
                    }
                });
                callback($scope.quoteData.submission);
            };

            $scope.saveQuote = () => {
                $scope.quoteData.submission.bindData.chosenQuote.value = $scope.quoteData.offeredQuotes.find((quote) => quote.isCustom === true).publicID;
                $scope.saveInFlight = true;
                RenewalService_AND.updateQuoted($scope.quoteData.submission.value)
                    .finally(() => {
                        $scope.saveInFlight = false;
                    });
            };

            $scope.printQuote = () => $window.print();

            $scope.isBeforeNewKeyDocDatTBB = () => {
                return moment($scope.quoteData.submission.baseData.periodStartDate.value).isBefore(moment('20231201').startOf('day'));
            }
            
            if ($rootScope.productCode === 'PersonalMotor'){
                $scope.quoteData.submission.bindData.boxDeclaration_itb = true;
            }
        }]
    };
}];