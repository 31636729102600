export default ['$scope', '$filter', 'StateUtils', '$q', 'StaticDocumentsService_AND', ($scope, $filter, StateUtils, $q, StaticDocumentsService_AND) => {
    const update = (qdd = $scope.quoteData) => {
        $scope.coverage = $scope.quoteData.getCoverage($scope.coverageCode, qdd.baseOffering);
        if ($scope.coverage) {
            $scope.coverageMonthlyCost = $scope.quoteData.getMonthlyAmount($scope.coverage);
        }
    };

    update();
    if (!$scope.coverage) { // Abort initialisation if coverage is not available
        return;
    }

    $scope.newSuperCoverAddressEffective = $scope.quoteData.submission.value.isPolicyStartDateBeforeSuperCoverAddressChange();

    $scope.coverageVM = {
        value: null,
        aspects: {
            required: true,
            availableValues: [true, false],
            get valid() {
                return angular.isDefined($scope.coverageVM.value) && $scope.coverageVM.value !== null;
            },
            get validationMessages() {
                if (this.valid === false) {
                    return [$filter('translate')('displaykey.Edge.Web.Api.Model.NotNull')];
                }
                return [];
            }
        }
    };
    // Init coverageVM value
    // If dealing with the base version, just map; otherwise:
    // It's selected if it comes from PC; if it's not selected in PC, pre-populate only if the selection has been
    // recorded previously (so it'll only be pre-populated with false).
    if ($scope.quoteData.submission.isBase_itb.value === true) {
        $scope.coverageVM.value = $scope.coverage.selected;
    } else if ($scope.quoteData.submission.isBase_itb.value === true || $scope.coverage.selected === true) {
        $scope.coverageVM.value = true;
    } else if (StateUtils.activeDataForState(true).isNewAmendedRenewalQuote) {
        $scope.coverageVM.value = null;
    } else {
        const coverageSelection = StateUtils.activeDataForState(true).coverageSelection;
        if (coverageSelection && coverageSelection[$scope.coverageCode].selected === false) {
            $scope.coverageVM.value = false;
        }
    }

    // Static Docs
    const submission = $scope.quoteData.submission.value;
    const retrieveDocs = () => $q.all([
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.CCCP_IPID, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.CCCP_POLICY, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.KEYS_IPID, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.KEYS_POLICY, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.BREAKDOWN_IPID_BASIC, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.BREAKDOWN_IPID_EURO, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.BREAKDOWN_POLICY, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.LEGAL_EXP_IPID, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.LEGAL_EXP_POLICY, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.ECCC_IPID, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.ECCC_POLICY, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.MOTOR_EXCESS_IPID, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.MOTOR_EXCESS_POLICY, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PERSONAL_ACCIDENT_IPID, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PERSONAL_ACCIDENT_POLICY, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.TOOLS_IN_TRANSIT_IPID, submission.baseData.periodStartDate),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.TOOLS_IN_TRANSIT_POLICY, submission.baseData.periodStartDate),
    ])
        .then((result) => {
            $scope.cccplusInsuranceProductInformationDocument = result[0];
            $scope.cccplusPolicyDocument = result[1];
            $scope.keysInsuranceProductInformationDocument = result[2];
            $scope.keysPolicy = result[3];
            $scope.breakdownBasicInsuranceProductInformationDocument = result[4];
            $scope.breakdowneuroInsuranceProductInformationDocument = result[5];
            $scope.breakdownPolicyDocument = result[6];
            $scope.legalExpensesInsuranceProductInformationDocument = result[7];
            $scope.legalExpensesPolicyDocument = result[8];
            $scope.EnhancedCourtesyCarInsuranceProductInformationDocument = result[9];
            $scope.EnhancedCourtesyCarPolicyDocument = result[10];
            $scope.MotorExcessProtectionInsuranceProductInformationDocument = result[11];
            $scope.MotorExcessProtectionPolicyDocument = result[12];
            $scope.IncreasedPersonalAccidentInsuranceProductInformationDocument = result[13];
            $scope.IncreasedPersonalAccidentPolicyDocument = result[14];
            $scope.ToolsInTransitInsuranceProductInformationDocument = result[15];
            $scope.ToolsInTransitPolicyDocument = result[16];
        });
    const retrieveDocsPromise = retrieveDocs();

    $scope.$watch('coverageVM.value', (newValue) => {
        if (newValue !== null) {
            if ($scope.coverage.selected !== newValue) {
                $scope.quoteData.updateCoverageSelection(newValue, $scope.coverageCode)
                    .then((qdd) => {
                        update(qdd);
                        $scope.coverageVM.value = $scope.coverage.selected; // For UI Feedback if the operation was successful or not
                        // set cache
                        updateCoverageSelectionCache(newValue, $scope.coverageCode);
                    });
            } else {
                // set cache
                updateCoverageSelectionCache(newValue, $scope.coverageCode);
            }
        }
    });
    $scope.selectOption = (option, $event) => {
        if ($event.target.tagName === 'A') { // Ignore clicks to the document anchor links
            return;
        }
        if ($scope.quoteData.controlsDisabled() !== true) {
            $scope.coverageVM.value = option;
        }
    };
    const updateCoverageSelectionCache = function (selected, patternCode) {
        const coverageSelection = StateUtils.activeDataForState(true).coverageSelection;
        // Initialise selection object if doesn't exist
        if (!coverageSelection) {
            StateUtils.activeDataForState(true).coverageSelection = {};
        } else {
            // If selection with the same value exists already -> no need to update
            const cov = coverageSelection[patternCode];
            if (cov && cov.selected === selected) {
                return;
            }
        }

        retrieveDocsPromise.then(() => {
            const doc = getDocForCoverage(patternCode);
            StateUtils.activeDataForState(true).coverageSelection[patternCode] = {
                selected: selected,
                docLink: doc.link,
                docLabel: doc.label
            };
        });
    };

    const getDocForCoverage = (patternCode) => {
        switch (patternCode) {
            case 'PMCourtesyCarPlusCov':
                return {
                    link: $scope.cccplusInsuranceProductInformationDocument,
                    label: 'and.quoteandbind.pm.views.quote.AddOns.CourtesyCarCoverPlus'
                };
            case 'PMKeyProtect':
            case 'PMKeyCov':
                return {
                    link: $scope.keysInsuranceProductInformationDocument,
                    label: $filter('andBrand')('and.quoteandbind.pm.views.quote.AddOns.KeyCoverTitle')
                };
            case 'PMLegalExpensesCov':
                return {
                    link: $scope.legalExpensesInsuranceProductInformationDocument,
                    label: 'and.quoteandbind.pm.views.quote.AddOns.LegalExpensesCover'
                };
            case 'TMEnhancedCourtesyCarCov':
                return {
                    link: $scope.EnhancedCourtesyCarInsuranceProductInformationDocument,
                    label: 'and.quoteandbind.pm.views.quote.AddOns.EnhancedCourtesyCarCover'
                };
            case 'TMExcessProtectionCov':
                return {
                    link: $scope.MotorExcessProtectionInsuranceProductInformationDocument,
                    label: 'and.quoteandbind.pm.views.quote.AddOns.MotorExcessProtectionCover'
                };
            case 'TMPersonalAccidentCov':
                return {
                    link: $scope.IncreasedPersonalAccidentInsuranceProductInformationDocument,
                    label: 'and.quoteandbind.pm.views.quote.AddOns.IncreasedPersonalAccidentCover'
                };
            case 'PMToolsInTransit':
                return {
                    link: $scope.ToolsInTransitInsuranceProductInformationDocument,
                    label: 'and.quoteandbind.pm.views.quote.AddOns.ToolsInTransit.kin'
                };
            default:
                return {};
        }
    };
}];